<template>
  <div>
    <div class="w700">
      <h1 class="main-head">{{ legal.title }}</h1>
      <p v-if="legal.effectiveDate" class="legal-date">
        Effective Date:{{ legal.effectiveDate }}
      </p>
      <div v-html="legal.content"></div>
    </div>
  </div>
</template>


<script>
export default {
  data: () => ({
    legal: "",
  }),
  async beforeMount() {
    let { data: legal } = await this.$db
      .collection("legalPages")
      .item("62d3e84b5700a1d38880032f")
      .get();
    this.legal = legal;
  },
  name: "Terms of Use",
};
</script>