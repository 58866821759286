<template >
  <div class="login-wrap">
    <div class="login-box">
      <div class="login-logo"><img src="../assets/logo.svg" /></div>
      <form class="login-form" @submit.prevent="login">
        <p class="login-head">Log In</p>
        <input v-model="email" type="email" placeholder="Email" class="login-input" />
        <input v-model="password" type="password" placeholder="Password" class="login-input" />
        <input type="submit" :value="loginTitle" class="btn full" />
        <p style="color:red">{{errMessage}}</p>
        <p class="small-login-txt">Forgot password? </p><router-link to="/password" class="login-link">Reset password</router-link>
      </form>
    </div>
  </div>
</template>

<script>
   export default {
    data(){
      return{
        loginTitle:'Log in',
        email:"",
        password:"",
        errMessage:"",
      }
    },
    methods:{
      async login() {
        if (this.loginTitle === 'Logging In...') return;
        this.loginTitle = "Logging In..."
        this.errMessage = '';
        try {
          await this.$users.validate(this.email, this.password)
          
          this.$router.push("/")
          }catch(err){
            this.loginTitle = 'Log in'
            let errMessage = err?.response?.data|| err?.message || 'There seems to have been an error'
            console.log(errMessage)
            if (errMessage.trim() === 'user not authorized []') errMessage = "Email & password combination don't match"
            this.errMessage = errMessage
            window.logMyError({res:JSON.stringify(err), context: this.email})
          }
      },
    },
   }
</script>