<template>
    <div class="forum-side-menu">
        <h1 class="forum-head">Forum</h1>
        <div>
            <router-link to="/forum" class="forum-menu-link">Recent activity</router-link>
            <router-link to="/posts" class="forum-menu-link">My Posts</router-link>
            <router-link to="/personal-account" class="forum-menu-link">Account settings</router-link>
        </div>
    </div>
</template>
      <script>
      export default {
          name: "ForumMenu"
      }
      </script>