<template>
  <div>
    <div class="other-cont">
      <div class="w1200">
        <router-link class="back" to="/content-hub">
          <img src="../assets/back.svg" alt="back">
          Back</router-link>
          <br>
          <br>
      </div>
      <div class="filler" v-if="!body">
      </div>
      <div  class="inner-art-wrap"  v-html="body">
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      item: {},
    };
  },
    watch:{
    '$route.params.id'(){
      this.getData()
    }
  },
  name: "Content-Hub",
  computed:{
    body() {
      return this.item?.body?.replace(/class=/g,'className=') ||''
    }
  },
  mounted() {
    this.getData(this.$route.params.id);
  },
  methods: {
    async getData(id) {
      const { data } = await this.$db.collection("contentHub").item(id).get()
      this.item = data;
    },
  },
};
</script>

<style scoped>
.back{
  margin-top: 30px;
  text-decoration: none;
  color: black;
}
.cont-tabs{
      margin: 40px auto 30px;
}
.tab{
  text-decoration: none;
  color: black;
}
.other-cont{
min-height: calc(100vh - 123px);
}

.filler {
  height: 90vh;
}
.inner-art-wrap{
  max-width: 700px;
  width: 90%;
  margin: 30px auto;
  min-height: 500px;
  position: relative;
}
</style>


<style>
  .inner-art-wrap video, .inner-art-wrap iframe {
  position: absolute;
  top: 0 ;
  left: 0;
  right: 0;
  width: 100%;
  max-height: 400px;
  height: 50vw;
}
</style>