<template >
  <div class="login-wrap">
    <div class="login-box">
      <div class="login-logo"><img src="../assets/logo.svg" /></div>
      <div v-if="sent">

        <p class="login-head">We sent you an email</p>
      <p>Check the insturctions in the email to reset your password</p>
      </div>
      <form v-else @submit.prevent="requestPassword" class="login-form">
        <p class="login-head">Reset password</p>
        <input v-model="email" type="email" placeholder="Email" class="login-input" />
        <input type="submit" value="Get reset link" class="btn full" />
        <p style="color:red">{{errMessage}}</p>
      </form>
    </div>
  </div>
</template>

      <script>
      export default {
        data(){
          return {
            sent:false,
            email:"",
            errMessage:""
          }
        },
      methods:{
        async requestPassword() {
        try {
          await this.$axios.post('/api/reset',this.email)
          this.sent = true;
          }catch(err){
            this.errMessage = err?.response?.data|| err?.message || 'There seems to have been an error'
            window.logMyError({res:JSON.stringify(err), context: this?.email||''})
          }
        },
        }
      }
      </script>