<template>
  <div>
    <div class="other-cont">
      <div class="w1200">
        <router-link class="back" to="/content-hub">
          <img src="../assets/back.svg" alt="back">
          Back</router-link>
        <h1 class="cont-head">{{this.$route.params.contentType}}</h1>
      </div>
      <div class="cont-boxes-wrap">
          <link-path class="content-box" v-for="item in content" :key="item._id" :to="item.body?`/content-hub/item/${item._id}`:item.link || (item.fileToDownland || {})">
          <img v-if="item.coverImage.imageURL" :src="item.coverImage.imageURL" class="cont-pic" />
          <img v-else src="../assets/logo.svg" class="cont-pic fallback" />
          <p class="cont-txt-box">
            {{ item.title }}
          </p>
          <div class="cont-icon-box">
            <div class="cont-icon-wrap">
              <img v-if="item.contentType.value === 'Video'" src="../assets/video-icon.svg" />
              <img v-if="item.contentType.value === 'Journal Club'" src="../assets/journal-icon.svg" />
              <img v-if="item.contentType.value === 'Great Reads'" src="../assets/reads-icon.svg" />
              <img v-if="item.contentType.value === 'Newsletter'" src="../assets/newsletter-icon.svg" />
              <img v-if="item.contentType.value === 'Training & Materials'" src="../assets/training.svg" />

            </div>
            <p class="cont-icon-txt">{{ item.contentType.value }}</p>
          </div>
        </link-path>
        

      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      content: [],
    };
  },


    watch:{
    '$route.params.contentType'(){
      this.getData()
    }
  },
  name: "Content-Hub",
  beforeMount() {
    this.getData();
  },
  methods: {
    async getData() {
      const {contentType} = this.$route.params
      const { data } = await this.$db.collection("contentHub")
      .query("draft","=",false)
      .query("contentType.value","=", contentType).get();
     this.content = data.filter(d=>d.contentType.value === contentType)
     const { links } = await this.$db.collection("contentHub")
      .query("draft","=",false)
      .get();
      this.links = links;
    },
  },
};
</script>

<style scoped>
.back{
  margin-top: 30px;
  text-decoration: none;
  color: black;
}
.cont-tabs{
      margin: 40px auto 30px;
}
.tab{
  text-decoration: none;
  color: black;
}
.other-cont{
min-height: calc(100vh - 123px);
}
</style>