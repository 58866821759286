<template >
  <div class="page-wrap">
    <div class="forum-wrap">
      <Forummenu />
      <div class="forum-main">
        <form @submit.prevent="handleSubmit">
          <div class="new-topic-wrap">
            <label for="" class="new-topic-label">New Topic</label>
            <div class="new-topic-input">
              <input v-model="newTopic.topicHead" placeholder="New topic subject..." class="new-topic-input-inner input-head" />
              <div id="pell"  @input="adjustHeight" placeholder="Your message here…" class="pell"></div>
              <div class="new-topic-input-inner forchips">
                <div class="chip" v-for="(chip, i) of chips" :key="chip.label">
                  {{ chip }}
                  <i @click="deleteChip(i)">✕</i>
                </div>
                <div style="position:relative">
                  <input placeholder="Add Tags" v-model="tagsInput" @input="debounce" @keydown.enter="saveChip" @keydown.delete="backspaceDelete">
                  <div class="tag-suggestions" v-if="recommended.length">
                    <span v-for="tag in recommended" :key="tag" class="tag" @click="addSuggestedTag(tag)">{{ tag }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="btn-forum-wrap">
              <input type="submit" value="Submit" class="btn forum" :disabled="isLoading" />
            </div>
          </div>
        </form>
        <router-link :to="'discussion/' + topic._id" v-for="topic in forum" :key="topic._id" class="topic link">
          <p class="topic-head">{{ topic.topicHead }}</p>
          <p v-html="topic.topicContent" class="topic-txt">
          </p>
          <div class="tags-wrap" v-if="topic.tags">
            <router-link v-for="tag in (topic.tags || '').split(', ')" :key="tag + topic._id" class="tag" :to="`/tag/${tag}`">{{ tag }}</router-link>
          </div>

          <div class="topic-info-wrap">
            <div class="topic-user" v-if="topic.user.length">
              <img v-if="topic.user[0].item.profileImage && topic.user[0].item.profileImage.imageURL" :src="topic.user[0].item.profileImage.imageURL" />
              <div v-else class="topic-userlet">{{ (topic.user[0].item.fullName || "").split("\s+").map(n => n[0]).join('') }}</div>
              <div>
                <p class="topic-user-name"> {{ topic.user[0].item.fullName }}
                </p>
                <p v-if="topic.user[0].item.brainQStaff" class="topic-user-title">BrainQ Staff</p>
              </div>
            </div>
            <div class="topic-info-txt-wrap">

              <p class="topic-info" v-if="topic.pin">Pinned</p>
              <p class="topic-info" v-if="topic.pin">|</p>
              <p class="topic-info"> {{ new Date(topic._createdDate).toLocaleDateString() }}</p>
              <p class="topic-info">|</p>
              <p class="topic-info">{{ topic.comments.length }} Replies</p>
            </div>

          </div>
        </router-link>
      </div>
    </div>
    <router-link to="/suggestion" class="suggestion-box">Suggestion?</router-link>
  </div>
</template>


<script>
import Forummenu from "@/components/ForumMenu.vue";
import pell from 'pell'
import linkifyHtml from 'linkify-html';
export default {
  name: 'Forum',
  components: {
    Forummenu,
  },
  data: () => ({
    isLoading: false,
    forum: [],
    newTopic: {
      topicHead: "",
      topicContent: "",
    },
    chips: [],
    tagsInput: '',
    recommended: [],
  }),
  beforeMount() {
    this.fetchData()
  },
  mounted(){
     pell.init({
      element: document.getElementById('pell'),
      onChange: html => this.newTopic.topicContent = linkifyHtml(html),
      editorPlaceholder: 'Add your text here...',
      actions: [
        'bold',
        'italic',
        'underline',
      ]
    })
  },
  watch:{
    tagsInput(v){
      if (v.match(/\s#$/)) {
        const newChips = v.split(/ #/).map(chip=>chip.replace('#','').trim()).filter(c=>!!c);
        for (let c of newChips)this.chips.push(c)
        this.tagsInput = '#';
      }
    }
  },
  methods: {
    debounce() {
      const delay = 400;
      clearTimeout(this.timeoutID)
      this.timeoutID = setTimeout(() => this.getRecommendations(), delay)
    },
    async getRecommendations() {
      const tagsInput = this.tagsInput.trim().replace(/#/,'');
      if (!tagsInput) { this.recommended = []; return; }
      const { data: messages } = await this.$db.collection("forum").projectOn("tags").query("tags", "regex", tagsInput).get()
      const allTags = messages.map(m => m.tags.split(", ")).flat()
      this.recommended = [...new Set(allTags)].filter(t => t.match(tagsInput))
    },
    addSuggestedTag(tag) {
      this.chips.push(tag);
      this.tagsInput = ''
      this.recommended = []
    },
    saveChip(e) {
      e.preventDefault()
      const { chips, tagsInput, set } = this;
      ((set && chips.indexOf(tagsInput) === -1) || !set) && chips.push(tagsInput);
      this.tagsInput = '';
    },
    deleteChip(index) {
      this.chips.splice(index, 1);
    },
    backspaceDelete({ which }) {
      if (which == 8 && !this.tagsInput) this.chips.pop()
    },
    async fetchData() {
      let { data: forum } = await this.$db
        .collection("forum")
        .everything()
        .sort("_createdDate", "desc")
        .get();
      this.forum = forum.sort((a, b) => b.pin - a.pin)
    },
    adjustHeight(event) {
      const el = event.target
      if (el.scrollHeight > el.clientHeight) el.style.height = (el.scrollHeight) + "px";
    },
    async handleSubmit() {
      this.isLoading = true;
      const { $db, $users } = this;
      const itemRefID = await $users.getBagelUserID()
      await $db.collection("forum").post({ ...this.newTopic, user: [{ itemRefID }], tags: this.chips.join(", ") })
      this.newTopic = {
        topicHead: "",
        topicContent: ""
      };
      document.querySelector('.pell-content').innerHTML = ''
      this.chips = []
      await this.fetchData()
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    }
  },
};
</script>

<style>
.btn.forum:disabled {
  background: #e0e0e0;
}

.pell-content{
  height: 130px;
  outline: none;
}

.pell-content a {
  color:#2D74B6
}

[contentEditable=true]:empty:before {
  content: attr(placeholder);
  opacity: 0.6;
}

.new-topic-input-inner {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

.new-topic-input-inner .chip {
  margin: -4px 4px;
  background: #e0e0e0;
  padding: 0px 4px;
  border-radius: 3px;
  display: flex;
  align-items: center;
}

.chip i {
  cursor: pointer;
  opacity: .56;
  margin-left: 8px;
}

.new-topic-input-inner input {
  flex: 1 1 auto;
  outline: none;
  border: none;
  background: transparent;
}
</style>