<template >
  <div v-if="homepage[0]">
    <popup width="900px" v-model="selectedMember">
      <div class="popup-bio" v-if="selectedMember">
        <img v-if="selectedMember.image.imageURL" :src="selectedMember.image.imageURL" class="committee-pic" />
        <p class="cont-name">{{ selectedMember.fullName }}</p>
        <div v-html="selectedMember.extendedBio"></div>
        <p class="committee-quote-lite">{{ selectedMember.quote }}</p>

      </div>
    </popup>
    <div class="hero">
      <h1 class="main-head">{{ homepage[0].mainHead }}</h1>
      <h1 class="main-head reg">{{ homepage[0].smallerHead }}</h1>
      <h2 class="main-sub">{{ homepage[0].subtitle }}</h2>
    </div>
    <div class="board">
      <div class="w1200">
        <div class="winners">
          <div class="winner-box">
            <p class="win-num">{{ consented[0].consented || "&nbsp;" }}</p>
            <p class="txt20 maxw">Patients screened<br>(weekly)</p>
            <div class="winner-wrap">
              <div v-for="consented in consented" :key="consented._id" class="winner-logo-wrap">
                <img v-if="consented.logo && consented.logo.imageURL" :src="consented.logo.imageURL" />
                <p v-else="">{{ consented.name }}</p>
              </div>
            </div>

          </div>
          <div class="winner-box">
            <p class="win-num">{{ recruited[0].recruited || "&nbsp;" }}</p>
            <p class="txt20 maxw">Highest<br>recruiting site</p>
            <div class="winner-wrap">
              <div v-for="recruited in recruited" :key="recruited._id" class="winner-logo-wrap">
                <img v-if="recruited.logo && recruited.logo.imageURL" :src="recruited.logo.imageURL" />
                <p v-else="">{{ recruited.name }}</p>
              </div>
            </div>
          </div>
          <div class="winner-box">
            <p class="win-num">{{ timeToTreatment[0].timeToTreatment || "0" }} </p>
            <p class="txt20 maxw">Admission to treatment (avg. days)</p>
            <div class="winner-wrap">
              <div v-for="timeToTreatment in timeToTreatment" :key="timeToTreatment._id" class="winner-logo-wrap">
                <img v-if="timeToTreatment.logo && timeToTreatment.logo.imageURL" :src="timeToTreatment.logo.imageURL" />
                <p v-else="">{{ timeToTreatment.name }}</p>
              </div>
            </div>
          </div>
          <div class="winner-box">
            <p class="win-num">1</p>
            <p class="txt20 maxw">Last recruit</p>
            <div class="winner-wrap">
              <div v-for="lastRecruit in lastRecruit" :key="lastRecruit._id" class="winner-logo-wrap">
                <img v-if="lastRecruit.logo && lastRecruit.logo.imageURL" :src="lastRecruit.logo.imageURL" />
                <p v-else="">{{ lastRecruit.name }}</p>
              </div>
            </div>
          </div>
          <div class="location">
            <h4 class="mid-head">{{ homepage[0].testingSitesHead }}</h4>
            <div class="location-wrap">
              <div class="winner-logo-wrap list" v-for="sites in testingSites" :key="sites._id + 'winner'">
                <img v-if="sites.logo && sites.logo.imageURL" :src="sites.logo.imageURL" />
                <p v-else="">{{ sites.name }}</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="timeline">
      <h2 class="blue-head" id="Timeline">{{ homepage[0].timelineHead }}</h2>
      <div class="timeline-wrap">
        <div class="timeline-box" v-for="(tlItem, i) in timeline" :key="tlItem._id">
          <div class="timeline-side-line"></div>
          <div class="timeline-side-sqr"></div>
          <p class="timeline-num">{{ i + 1 }}</p>
          <p class="timeline-box-name">{{ tlItem.title }}</p>

          <!-- <a href="" class="timeline-link">screening log</a>
            <a href="" class="timeline-link">Inclusion/Exclusion Criteria</a> -->
          <div class="timeline-box-inner" v-html="tlItem.content">
          </div>
          <div class="timeline-drop" :class="{ opendrop: opendrop == stage._id }" v-for="stage in tlItem.subStages" :key="stage._id + 'stage'">
            <div class="timeline-drop-head" @click="toggleSubsection(stage)">
              <p>{{ stage.title }}</p>
              <img src="../assets/side-arrow.svg" class="drop-arrow" />
            </div>
            <div class="timeline-drop-cont" v-html="stage.content">
            </div>
          </div>
        </div>
        <div class="timeline-mid-line"></div>
      </div>
    </div>
    <div id="Content" class="committee">
      <h4 class="mid-head">{{ homepage[0].contentHead }}</h4>
      <div class="committee-wrap">
        <!-- <a class="content-box" v-for="contnet in featuredArticles" :key="contnet._id + 'content'" :href="contnet.link || (contnet.fileToDownland || {}).imageURL" target="_blank"> -->
          <link-path class="content-box" v-for="contnet in featuredArticles" :key="contnet._id+'content'" :to="contnet.body?`/content-hub/item/${contnet._id}`:contnet.link || (contnet.fileToDownland || {})">
          <img v-if="contnet.coverImage && contnet.coverImage.imageURL" :src="contnet.coverImage.imageURL" class="cont-pic" />
          <img v-else src="../assets/logo.svg" class="cont-pic fallback" />

          <p class="cont-txt-box">
            {{ contnet.title }}
          </p>
          <div class="cont-icon-box">
            <div class="cont-icon-wrap">
              <img v-if="contnet.contentType.value === 'Video'" src="../assets/video-icon.svg" />
              <img v-if="contnet.contentType.value === 'Journal Club'" src="../assets/journal-icon.svg" />
              <img v-if="contnet.contentType.value === 'Great Reads'" src="../assets/reads-icon.svg" />
              <img v-if="contnet.contentType.value === 'Newsletter'" src="../assets/newsletter-icon.svg" />
              <img v-if="contnet.contentType.value === 'Training & Materials'" src="../assets/training.svg" />
            </div>
            <p class="cont-icon-txt">{{ (contnet.contentType || {}).value }}</p>
          </div>
          </link-path>


      </div>
      <div class="center-wrap">
        <router-link to="/content-hub" class="btn wide">See More</router-link>
      </div>
    </div>
    <div id="Committee" class="committee">
      <h4 class="mid-head">{{ homepage[0].committeeHead }}</h4>
      <div class="committee-wrap">
        <div v-for="member in steeringCommittee" :key="member._id" class="committee-box">
          <img v-if="member.image && member.image.imageURL" :src="member.image.imageURL" class="committee-pic" />
          <p class="committee-name">{{ member.fullName }}</p>
          <p class="committee-title">{{ cleanHTML(member.bio) }}</p>
          <p class="committee-quote">{{ member.quote }}</p>
          <button @click="selectedMember = member" type="button" class="bio-btn">Read Full Bio</button>
        </div>
      </div>
    </div>

    <div id="Contact" class="contact">
      <div class="w1200">
        <h4 class="mid-head">{{ homepage[0].contactHead }}</h4>
        <div class="cont-list">
          <div class="cont-box" v-for="member in contactInfo" :key="member._id">
            <div class="cont-info">
              <p class="cont-name">{{ member.name }}</p>
              <p class="cont-title">{{ member.title }}</p>
              <p class="cont-info-txt">{{ member.phone }}</p>
              <a style="color:white" target="_blank" :href="`mailto:${member.email}`" class="cont-info-txt">{{ member.email }}</a>
              <p class="cont-info-txt">{{ member.location }}</p>
            </div>
            <p class="cont-small">{{ member.topic }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedMember: false,
      steeringCommittee: [],
      contentHub: [],
      contactInfo: [],
      timeline: [],
      opendrop: {},
      homepage: {},
      testingSites: []
    }
  },
  computed: {
    featuredArticles() {
      const featuredArticles = this.contentHub.filter(a => a.featured);
      const contentTypes = new Set(featuredArticles.map(a => a.contentType.value));

      const oneOfEachContentType = [];

      featuredArticles.forEach(a => {
        const articleCT = a.contentType.value;
        if ([...contentTypes].includes(articleCT) && oneOfEachContentType.every(({ contentType: { value } }) => value !== articleCT)) {
          oneOfEachContentType.push(a);
        }
      }
      );
      return oneOfEachContentType
    },
    consented() {
      const { testingSites } = this;
      let consented = testingSites
        .filter((b) => b.consented !== undefined)
        .sort((a, b) => b.consented - a.consented);
      const topConsentedVal = consented[0].consented;

      let matchingConsentedVals = consented.filter((b) => b.consented === topConsentedVal);

      return matchingConsentedVals;
    },
    recruited() {
      const { testingSites } = this;
      let recruited = testingSites
        .filter((b) => b.recruited !== undefined)
        .sort((a, b) => b.recruited - a.recruited);
      const topRecruitedVal = recruited[0].recruited;

      let matchingRecruitedVals = recruited.filter((b) => b.recruited === topRecruitedVal);

      return matchingRecruitedVals;
    },
    timeToTreatment() {
      const { testingSites } = this;
      let timeToTreatment = testingSites
        .filter((b) => b.timeToTreatment !== undefined)
        .sort((a, b) => (a.timeToTreatment - b.timeToTreatment));
      const topTimeToTreatmentVal = timeToTreatment[0].timeToTreatment;

      let matchingTimeToTreatmentVals = timeToTreatment.filter((b) => b.timeToTreatment === topTimeToTreatmentVal);

      return matchingTimeToTreatmentVals
    },
    lastRecruit() {
      const { testingSites } = this;
      let lastRecruit = testingSites
        .filter((b) => b.lastRecruit !== undefined)
        .sort((a, b) => new Date(b.lastRecruit) - new Date(a.lastRecruit));
      const topLastRecruitVal = lastRecruit[0].lastRecruit;

      let matchingLastRecruitVals = lastRecruit.filter((b) => b.lastRecruit === topLastRecruitVal);

      return matchingLastRecruitVals
    }
  },
  methods: {
    toggleSubsection(stage) {
      if (this.opendrop === stage._id) this.opendrop = false;
      else this.opendrop = stage._id
    },
    cleanHTML(html) {
      return html.replace(/<(?:.|\s)*?>/g, "").replace(/&nbsp;/, " ")
    },
    async fetchData() {
      for (let key of Object.keys(this.$store)) this[key] = this.$store[key];
    }
  },
  mounted() { this.fetchData() },
  name: "Home"
};
</script>
