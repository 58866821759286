<template>
  <div>
    <h1 class="cont-head">Content hub</h1>
    <div class="cont-gray">
      <div class="cont-gray-wrap">
        <div class="cont-col-1">
          <div class="cont-small-head">
            <img src="../assets/blue-icon-2.svg" />
            <p>Journal club</p>
          </div>
          <a class="cont-big-box" :href="featuredJC.link || (featuredJC.fileToDownland || {}).imageURL" target="_blank">
            <img v-if="featuredJC.coverImage.imageURL" :src="featuredJC.coverImage.imageURL" />
            <img v-else src="../assets/logo.svg" class="cont-pic fallback" />

            <p class="cont-txt">{{ featuredJC.title }}</p>
          </a>
        </div>
        <div class="cont-col-2">
          <div class="cont-small-head">
            <!-- <img src="../assets/blue-icon-2.svg" /> -->
            <!-- <p>Journal club</p> -->
          </div>

          <link-path class="cont-mid-box" v-for="great in featuredGR" :key="great._id" :to="great.body?`/content-hub/item/${item._id}`:great.link || (great.fileToDownland || {}).imageURL" >
            <img :src="great.coverImage.imageURL" />
            <p class="cont-txt">{{ great.title }}</p>
          </link-path>
        </div>
        <div class="cont-col-3">
          <div class="cont-small-head">
            <img src="../assets/blue-icon-3.svg" />
            <p>Newsletter</p>
          </div>
          <div v-for="item in featuredNL" :key="item._id">
            <link-path class="newsletter-box"  :to="item.body?`/content-hub/item/${item._id}`:item.link || (item.fileToDownland || {}).imageURL">
              <img :src="item.coverImage.imageURL" />
              <p class="newsletter-txt">{{ item.title }}</p>
            </link-path>
          </div>
        </div>
      </div>
    </div>

    <div class="other-cont">
      <div class="cont-tabs">
        <!-- <p class="tab" @click="activeTab = tab" :class="{ open: activeTab === tab }" v-for="tab in fitlerItems" :key="tab">{{ tab }}</p> -->
        <!-- <p class="tab" @click="activeTab = 'All'" :class="{ open: activeTab === 'All' }">All</p> -->
        <router-link v-for="tab in fitlerItems" :key="tab._id"  class="tab" :to="`/content-hub/${tab}`">{{ tab }}</router-link>
         <p class="tab bold">All</p>

      </div>
      <div class="cont-boxes-wrap">
        <link-path class="content-box" :to="item.body?`/content-hub/item/${item._id}`:item.link || (item.fileToDownland || {}).imageURL" v-for="item in rest" :key="item._id">
          <img v-if="item.coverImage.imageURL" :src="item.coverImage.imageURL" class="cont-pic" />
          <img v-else src="../assets/logo.svg" class="cont-pic fallback" />

          <p class="cont-txt-box">
            {{ item.title }}
          </p>
          <div class="cont-icon-box">
            <div class="cont-icon-wrap">
              <img v-if="item.contentType.value === 'Video'" src="../assets/video-icon.svg" />
              <img v-if="item.contentType.value === 'Journal Club'" src="../assets/journal-icon.svg" />
              <img v-if="item.contentType.value === 'Great Reads'" src="../assets/reads-icon.svg" />
              <img v-if="item.contentType.value === 'Newsletter'" src="../assets/newsletter-icon.svg" />
              <img v-if="item.contentType.value === 'Training & Materials'" src="../assets/training.svg" />
            </div>
            <p class="cont-icon-txt">{{ item.contentType.value }}</p>
          </div>
        </link-path>

      </div>
    </div>
  </div>
</template>

<script>


export default {
  data() {
    return {
      content: [],
      activeTab: "All",
      fitlerItems: ["Journal Club", "Training & Materials", "Newsletter", "Video"]
    };
  },
  computed: {
    featuredJC() {
      return this.content.filter((c) => c.contentType?.value === "Journal Club").filter((n) => n.featured)?.[0] || { coverImage: {} }
    },
    featuredGR() {
      return this.content
        .filter((c) => c.contentType?.value === "Journal Club")
        .filter((n) => n.featured)
        .slice(1, 3)
    },
    featuredNL() {
      return this.content
        .filter((c) => c.contentType?.value === "Newsletter")
        .slice(0, 7)
    },
    rest() {
      const { featuredGR, featuredJC, featuredNL, activeTab } = this;
      const rest = this.content
        .filter((c) => !featuredNL.find((f) => f._id === c._id))
        .filter((c) => !featuredGR.find((f) => f._id === c._id))
        .filter((c) => featuredJC._id !== c._id)
      if (activeTab !== 'All') return rest.filter((c) => c.contentType?.value === activeTab)
      return rest;
    },
  },
  name: "Content-Hub",
  beforeMount() {
    this.getData();
  },
  methods: {
    async getData() {
      const { data } = await this.$db.collection("contentHub").query("draft","=",false).get();
      this.content = data;
    },
  },
};
</script>

<style scoped>
.bold{
  font-weight: 600;
}
.tab{
  text-decoration: none;
  color: black;
}
</style>