<template>
  <div class="nav-wrap">
    <router-link to="/" class="logo-wrap"><img src="../assets/logo.svg" /></router-link>
    <div class="nav-links">
      <div class="nav-links-wrap" :class="{ open: opennavbar }"  @click="opennavbar = false">
        <button @click="opennavbar = false" type="button" class="close-nav">
          <img src="../assets/close.svg" />
        </button>
        <a href="/#Timeline" class="nav-link">Trial Timeline</a>
        <a href="/#Committee" class="nav-link">Steering Committee</a>
        <router-link to="/content-hub" class="nav-link">Content Hub</router-link>
        <a href="/#Contact" class="nav-link">Contact Us</a>
        <div class="flex-space"></div>
        <!-- <div class="search-wrap"><input type="" placeholder="Search" class="search-input">
          <button type="button" class="search-btn">
            <img src="../assets/search.svg"></button>
        </div> -->
        <router-link to="/forum" class="btn top">Forum</router-link>

      </div>
      <div class="mobile-flex-space"></div>
      <div class="nav-end-links">
        <div v-click-outside="closeNotify" class="notification-wrap">
          <button type="button" @click="openNotify" class="notification">
            <img src="../assets/bell.svg" />
             <div class="close-notification" :class="{ open: opennotification }">x</div>
            <p v-if="notifyCounter" class="notification-num">
              {{ notifyCounter }}
            </p>
          </button>
          <div class="notification-msg-wrap" :class="{ open: opennotification }">
            <div class="notification-msg" v-for="notify in notifications" :key="notify._id">
            <!-- <div class="close-notification">x</div> -->
      <a :target="notify.actionLink.match('portal.emagine.care')?'_self':'_blank'" :href="notify.actionLink.replace(/https*:\/\/portal\.emagine\.care/,'')">
                <p>{{ notify.message }}</p>
                <div v-if="notify.actionLink" class="center-wrap">
                  <div class="btn light">See More</div>
                </div>
      </a>
            </div>
          </div>
        </div>
        <div @click="openuser = !openuser" class="user-btn">
          <img class="profile-image" v-if="user.profileImage && user.profileImage.imageURL" :src="user.profileImage.imageURL" />
          <div v-else class="userlet">{{ (user.fullName || "").split("\s+").map(n => n[0]).join('') }}</div>
          <div class="user-box" :class="{ open: openuser }">
            <p class="username">{{ user.fullName }}</p>
            <p>
              <router-link to="/personal-account">Account settings</router-link>
            </p>
            <button class="logoutbtn" @click="logout">
              Logout
            </button>
          </div>
        </div>
      </div>
      <button @click="opennavbar = true" type="button" class="menu">
        <img src="../assets/menu.svg" />
      </button>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    opennavbar: false,
    opennotification: false,
    openuser: false,
    notifyCounter: 0,
    notifications: [],
    user: {},
  }),
  watch: {
    "$route.hash"(hash) {
      this.scrollToID(hash)
    },
  },
  name: "top-nav",
  mounted() {
    this.getNotifications();
    this.getUserInfo()
    this.scrollToID()

  },
  methods: {
    scrollToID(hash) {
      const id = (hash || this.$route.hash || '').replace("#", '');
      if (id) setTimeout(() => document.getElementById(id).scrollIntoView({ behavior: "smooth" }), 50)
    },
    async getUserInfo() {
      const { $db, $users } = this;
      const userID = await $users.getBagelUserID();
      const { data: user } = await $db.collection("users").item(userID).get();
      this.user = user;
    },
    closeNotify(){
      
        if (!this.opennotification) return;
        this.opennotification = false;
      
    },
    openNotify() {
      this.$cookies.set(
        "notifications",
        this.notifications.map((n) => n._id)
      );
      this.opennotification = !this.opennotification;
      this.notifyCounter = 0;
    },
    async getNotifications() {
      var fortnightAway = new Date(Date.now() - 12096e5);
      const { data } = await this.$db
        .collection("notifications")
        .query("_createdDate", ">", fortnightAway)
        .sort("_createdDate", "desc")
        .get();
      const readNotifications = this.$cookies.get("notifications");
      const readNotify = (readNotifications || "").split(",");
      this.notifications = data;
      this.notifyCounter = (this.notifications || []).filter(
        (n) => !readNotify.includes(n._id)
      ).length;
    },
    async logout() {
      await this.$users.logout();
      this.$router.push("/login");
    },
  },
};
</script>

<style>

</style>
