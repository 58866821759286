<template> 
<div>
  <button style="position: absolute;top: 10px;left: calc(50% - 70px);" class="btn center" @click="savePDF" v-if="!$route.query.p">Save as PDF</button>
  <div v-html="body">
  </div>
</div>   
</template>
<script>
export default {
  components: {
  },
  data() {
    return {
      item: {},
    };
  },
    watch:{
    '$route.params.id'(){
      this.getData()
    }
  },
  name: "Content-Hub",
  computed:{
    body() {
      return this.item?.body?.replace(/class=/g,'className=') ||''
    }
  },
  mounted() {
    this.getData(this.$route.params.id);
  },
  methods: {
  savePDF() {
  var pageUrl = encodeURIComponent(window.location.href+'?p=1');
  var opts = ['save-link=' + pageUrl, 'pageOrientation=auto', 'viewportWidth=620','delay=5'];
  const url = 'https://www.sejda.com/html-to-pdf?' + opts.join('&');
  console.log(url)
  window.open(url);
   },
    async getData(id) {
      const { data } = await this.$db.collection("contentHub").item(id).get()
      this.item = data;
    },
  },
};
</script>

<style scoped>
.back{
  margin-top: 30px;
  text-decoration: none;
  color: black;
}
.cont-tabs{
      margin: 40px auto 30px;
}
.tab{
  text-decoration: none;
  color: black;
}
.other-cont{
min-height: calc(100vh - 123px);
}

.filler {
  height: 90vh;
}
</style>