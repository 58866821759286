import search from "../views/Search.vue";
import discussion from "../views/Discussion.vue";
import forum from "../views/Forum.vue";
import tag from "../views/Tag.vue";
import posts from "../views/Posts.vue";
import personalaccount from "../views/Personalaccount.vue";
import contenthub from "../views/Contenthub.vue";
import contenthubPage from "../views/ContenthubPage.vue";
import contenthubPageBody from "../views/ContenthubPageBody.vue";
import contenthubPageBodyPDF from "../views/ContenthubPageBodyPDF.vue";
import termsandconditions from "../views/Termsandconditions.vue";
import privacypolicy from "../views/Privacypolicy.vue";
import password from "../views/Password.vue";
import signup from "../views/Signup.vue";
import suggestion from "../views/Suggestion.vue";
import login from "../views/Login.vue";
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
// import uploadCSV from '../views/uploadCSV.vue'
import Bagel from "@bageldb/bagel-db";
import BagelToken from "../../token";

const $db = new Bagel(BagelToken);
const $users = $db.users();
$users.axios.interceptors.request.use((request)=>{
  if (request.url.match('auth.bageldb')){
    const path = request.url.split('public/').pop()
    request.url = `/api/auth/${path}`
  }
  return request
})
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: login,
  },
  {
    path: "/signup",
    name: "Signup",
    component: signup,
  },
  {
    
      path: "/signup/:id",
      name: "SignupInvite",
      component: signup,
  },
  {
    path: "/password",
    name: "Password",
    component: password,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: privacypolicy,
  },
  {
    path: "/terms-and-conditions",
    name: "TermsAndConditions",
    component: termsandconditions,
  },
  {
    path: "/content-hub",
    name: "ContentHub",
    component: contenthub,
  },
  {
    path: "/personal-account",
    name: "PersonalAccount",
    component: personalaccount,
  },
  {
    path: "/forum",
    name: "Forum",
    component: forum,
  },
  {
    path: "/posts",
    name: "Posts",
    component: posts,
  },
  {
    path: "/discussion/:id",
    name: "Discussion",
    component: discussion,
  },
  {
    path: "/tag/:tagName",
    name: "Tag",
    component: tag,
  },
  {
    path: "/content-hub/:contentType",
    name: "content",
    component: contenthubPage,
  },
  {
    path: "/content-hub/item/:id",
    name: "contentBody",
    component: contenthubPageBody,
  },
  {
    path: "/content-hub/pdf/:id",
    name: "contentBodyPDF",
    component: contenthubPageBodyPDF,
  },
  {
    path: "/suggestion",
    name: "Suggestion",
    component: suggestion,
  },
  {
    path: "/search",
    name: "Search",
    component: search,
  }
  // {
  //   path: '/uploadCSV',
  //   name: 'uploadCSV',
  //   component: uploadCSV
  // },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  let user = {};
  const publicPages = ["Login", "Signup", "Password", "contentBodyPDF"];
  const authRequired = !publicPages.includes(to.name);
  try {
    const res = await $users.getUser();
    user = res?.data;
  } catch (err) {
    console.log(err);
    $users.logout();
  }
  if (!authRequired && user?.userID && to.name != 'contentBodyPDF') return next({ path: "/" });
  if (authRequired && !user?.userID) return next({ path: "/login" });
  else next();
});

export default router;
