<template>
  <div class="login-wrap">
    <div class="login-box" v-if="loaded">
      <div class="login-logo"><img src="../assets/logo.svg" /></div>
      <div v-if="inviteMessage" v-html="inviteMessage"></div>
      <form v-else @submit.prevent="createAccount" class="login-form">
        <p class="login-head">Sign up</p>
        <input
          v-model="user.fullName"
          type="text"
          placeholder="Full Name"
          class="login-input"
        />

        <input
          v-model="user.email"
          type="email"
          placeholder="Email"
          class="login-input"
        />
        <input
          v-model="password"
          type="password"
          placeholder="Password"
          class="login-input"
        />
        <input
          v-model="password2"
          type="password"
          placeholder="Password again"
          class="login-input"
        />

        <input
          type="submit"
          :value="actionMessage"
          :disabled="password2 !== password"
          class="btn full"
        />
        <p style="color: red">{{ errMessage }}</p>
        <p class="small-login-txt">Already a member?</p>
        <router-link to="/login" class="login-link">Log in</router-link>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      actionMessage: "Sign up",
      loaded: true,
      sent: false,
      user: {
        fullName: "",
        email: "",
      },
      password: "",
      password2: "",
      errMessage: "",
      inviteMessage: "",
    };
  },
  async mounted() {
    const { id } = this.$route.params;
    if (!id) {
      this.loaded = true;
      return;
    }
    const { data } = await this.$db.collection("invitations").item(id).get();
    if (data.used) {
      this.loaded = true;
      return;
    }
    this.user.email = data.email;
    this.user.fullName = data.name;
    this.loaded = true;
  },
  methods: {
    async createAccount() {
      this.errMessage = "";
      try {
        const { id } = this.$route.params;
        this.actionMessage = "Submitting...";
        // const {$encrypt} = this;
        // let en = `${$encrypt(this.user.email)};;;${$encrypt(this.password)};;;${$encrypt(this.user.fullName)}`;
        // let en = `${this.user.email};;;${this.password};;;${this.user.fullName}`;

        
        const userID = await this.$users.create(
          this.user.email,
          this.password
        );
        const { email, fullName } = this.user;
        await this.$db
          .collection("users")
          .item(userID)
          .set({ email, fullName });
        if (id)
          await this.$db.collection("invitations").item(id).put({ used: true });
        this.$router.push("/");
        this.actionMessage = "Done";
      } catch (err) {
        console.log(err);
        (this.actionMessage = "Sign up"),
          (this.errMessage =
            err?.response?.data ||
            err?.message ||
            "There seems to have been an error");
        // window.logMyError({res:JSON.stringify(err), context: this?.user?.email||''})
      }
    },
  },
};
</script>

<style>
input[disabled] {
  opacity: 0.4;
  cursor: not-allowed;
}
</style>
