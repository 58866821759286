<template>
  <div id="app">
    <top-nav  v-if="!openRoutes"/>
    <router-view/>
    <botfooter  v-if="!openRoutes"/>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import TopNav from "@/components/TopNav.vue";
export default {
  name: "App",
  components:{
    Botfooter,
    TopNav
  },
  computed:{
    openRoutes(){
      const publicRoutes = ["Login","Signin","Signup","Password",'contentBodyPDF'];
      return publicRoutes.includes(this.$route.name);
    }
  }
}
</script>

<style src="./main.css"></style>