<template>
    <div class="footer">
        <router-link to="/" class="logo-footer"><img src="../assets/logo-white.svg"></router-link>
        <div class="footer-links-wrap">
            <router-link to="/privacy-policy" class="footer-link">Privacy Policy</router-link>
            <router-link to="/terms-and-conditions" class="footer-link"> Terms of Use</router-link>
        </div>
    </div>
</template>
      <script>
      export default {
          name: "botfooter"
      }
      </script>