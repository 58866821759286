<template >
  <div class="page-wrap">
    <div class="forum-wrap">
      <Forummenu />
      <div class="forum-main">
        <h2 class="forum-small-head">Account settings</h2>
        <form action="" class="form-account">
          <div class="forum-image-wrap">
            <div v-if="uploading" class="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <input @change="uploadNewImage" class="change-image-input" type="file" id="img" name="img" accept="image/*">
            <img class="profile-image" v-if="user.profileImage && user.profileImage.imageURL" :src="user.profileImage.imageURL" />
            <div v-else class="userlet bigger">{{ (user.fullName || "").split("\s+").map(n => n[0]).join('') }}</div>
            <div type="button" class="change-image-btn">
              <p>Change picture </p>
            </div>
          </div>
          <form @submit.prevent="handleSubmit" class="forum-form-wrap"><label for="" class="label">Display Name</label>
            <input type="text" :placeholder="user.fullName" class="forum-input" v-model="user.fullName" />
            <label for="" class="label">About Me</label>
            <textarea @input="adjustHeight" id="Message" name="Message" placeholder="Tell us a bit about yourself" class="forum-input" v-model="user.about"></textarea>
            <input type="submit" value="Save" class="btn forum" />
          </form>
        </form>
      </div>
    </div>
  </div>
</template>


<script>
import Forummenu from "@/components/ForumMenu.vue";
export default {
  components: {
    Forummenu,
  },
  data: () => ({
    user: {},
    uploading: false,
  }),
  mounted() {
    this.getUserInfo()
  },
  methods: {
    async uploadNewImage(event) {
      const file = event.target.files[0];
      if (!file) return;
      const image = {}
      image.selectedImage = file;
      image.name = file.name;
      [, image.extension] = file.name.split('.'); // ? getting the item at index 1 with array destructuring
      this.uploading = true;
      console.log(this.user._id)
      try {
        const call = this.$db.collection("users").item(this.user._id)
        await call.uploadImage("profileImage", image);
        this.getUserInfo()
        this.uploading = false;
      } catch (err) {
        this.uploading = false;
      }
    },
    adjustHeight(event) {
      const el = event.target
      el.style.height = (el.scrollHeight > el.clientHeight) ? (el.scrollHeight) + "px" : "60px";
    },
    async getUserInfo() {
      const { $db, $users } = this;
      const userID = await $users.getBagelUserID();
      const { data: user } = await $db.collection("users").item(userID).get();
      this.user = user;
    },
    async handleSubmit() {
      const { $db } = this;
      await $db.collection("users").item(this.user._id).put(this.user)
    }
  },
  name: "Account-settings",

};
</script>

<style>
.lds-ring {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 145px;
  height: 145px;
  margin: 0;
  border: 4px solid #90bf56;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #90bf56 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>