<template >
  <div>
    <div class="page-wrap">
      <div class="forum-wrap">
        <Forummenu />

        <div class="forum-main">
          <div class="topic notlink">
            <p class="topic-head">{{ topic.topicHead }}</p>
            <div class="topic-info-wrap">
              <div class="topic-user" v-if="topicUser.fullName">
                <img v-if="topicUser.profileImage.imageURL" :src="topicUser.profileImage.imageURL" />
                <div v-else class="topic-userlet">{{ (topicUser.fullName || "").split("\s+").map(n => n[0]).join('') }}</div>
                <div>
                  <p class="topic-user-name"> {{ topicUser.fullName }}
                  </p>
                  <p v-if="topicUser.brainQStaff" class="topic-user-title">BrainQ Staff</p>
                </div>
              </div>

              <div class="topic-info-txt-wrap">
                <p class="topic-info">{{ new Date(topic._createdDate).toLocaleDateString() }}</p>
                <p class="topic-info">|</p>
                <!-- <p class="topic-info">{{ topic.views }} Views</p> -->
                <!-- <p class="topic-info">|</p> -->
                <p class="topic-info">{{ (topic.comments || []).length }} Replies</p>
              </div>

            </div>
            <div class="tags-wrap" v-if="topic.tags">
              <router-link v-for="tag in (topic.tags || '').split(', ')" :key="tag + topic._id" class="tag" :to="`/tag/${tag}`">{{ tag }}</router-link>
            </div>
            <div v-html="topic.topicContent"></div>
          </div>

          <div v-for="comment in topic.comments" :key="comment._id" class="topic-comment">
            <div class="topic-info-wrap">
              <div class="topic-user">
                <img v-if="commentUser(comment).profileImage.imageURL" :src="commentUser(comment).profileImage.imageURL" />
                <div v-else class="topic-userlet">{{ (commentUser(comment).fullName || "").split("\s+").map(n => n[0]).join('') }}</div>
                <div>
                  <p class="topic-user-name"> {{ commentUser(comment).fullName }}
                  </p>
                  <p v-if="commentUser(comment).brainQStaff" class="topic-user-title">BrainQ Staff</p>
                </div>
              </div>
              <div class="topic-info-txt-wrap">
                <p class="topic-info">{{ new Date(comment._createdDate).toLocaleDateString() }}</p>

              </div>

            </div>
            <div v-html="comment.commentContent"></div>
          </div>


          <div class="new-comment">
            <form @submit.prevent="addResponse">
              <div class="new-topic-wrap">
                <label for="" class="new-topic-label">Add a Response</label>
                <textarea @keydown.enter="trySubmit" v-model="commentContent" id="Message" name="Message" placeholder="Add a new comment here…" class="new-topic-input"></textarea>
                <input type="submit" value="Submit" class="btn forum" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <router-link to="/suggestion" class="suggestion-box">Suggestion?</router-link>

  </div>
</template>



<script>
import Forummenu from "@/components/ForumMenu.vue";
export default {
  components: {
    Forummenu,
  },
  data: () => ({
    topic: "",
    commentContent: ""
  }),
  computed: {
    topicUser() {
      if (!this.topic?.user?.length) return { profileImage: {} }
      return this.topic.user[0].item;
    },
  },
  methods: {
    commentUser(comment) {
      if (!comment?.user?.length) return { profileImage: {} }
      return comment.user[0].item;
    },
    trySubmit(event) {
      if (event.metaKey || event.ctrlKey) {
        event.preventDefault()
        this.addResponse()
      }
    },
    async addResponse() {
      const { id } = this.$route.params
      const { commentContent } = this;
      const itemRefID = await this.$users.getBagelUserID();
      await this.$db.collection("forum").item(id).collection("comments").post({ commentContent, user: [{ itemRefID }] })
      this.commentContent = "";
      this.fetchData()
    },
    async fetchData() {
      const { id } = this.$route.params
      let { data: topic } = await this.$db
        .collection("forum")
        .everything()
        .item(id)
        .get();
      this.topic = topic;
    }
  },
  beforeMount() {
    this.fetchData()
  },
  name: "Forum-Discussion",
};
</script>

