<template >
  <div class="page-wrap">
    <div class="forum-wrap">
      <Forummenu />
      <div class="forum-main">
        <router-link :to="'discussion/' + topic._id" v-for="topic in forum" :key="topic._id" class="topic link">
          <p class="topic-head">{{ topic.topicHead }}</p>
          <div class="tags-wrap" v-if="topic.tags">
            <router-link v-for="tag in (topic.tags || '').split(', ')" :key="tag + topic._id" class="tag" :to="`/tag/${tag}`">{{ tag }}</router-link>
          </div>
          <p v-html="topic.topicContent" class="topic-txt">
          </p>
          <div class="topic-info-wrap">
            <div class="topic-user">
              <img v-if="topic.user[0].item.profileImage && topic.user[0].item.profileImage.imageURL" :src="topic.user[0].item.profileImage.imageURL" />
              <div v-else class="topic-userlet">{{ (topic.user[0].item.fullName || "").split("\s+").map(n => n[0]).join('') }}</div>
              <div>
                <p class="topic-user-name"> {{ topic.user[0].item.fullName }}
                </p>
                <p v-if="topic.user[0].item.brainQStaff" class="topic-user-title">BrainQ Staff</p>
              </div>
            </div>
            <div class="topic-info-txt-wrap">

              <p class="topic-info"> {{ new Date(topic._createdDate).toLocaleDateString() }}</p>
              <p class="topic-info">|</p>
              <!-- <p class="topic-info">{{ topic.views }} Views</p> -->
              <!-- <p class="topic-info">|</p> -->
              <p class="topic-info">{{ topic.comments.length }} Replies</p>
            </div>

          </div>
        </router-link>
      </div>
    </div>
    v
  </div>
</template>


<script>
import Forummenu from "@/components/ForumMenu.vue";
export default {
  components: {
    Forummenu,
  },
  data: () => ({
    forum: [],
    newTopic: {
      topicHead: "",
      topicContent: ""
    },
  }),
  beforeMount() {
    this.fetchData()
  },

  methods: {
    async fetchData() {
      const itemRefID = await this.$users.getBagelUserID()
      let { data: forum } = await this.$db
        .collection("forum")
        .everything()
        .query("user.itemRefID", "=", itemRefID)
        .sort("_createdDate", "desc")
        .get();
      this.forum = forum;
    },
    adjustHeight(event) {
      const el = event.target
      el.style.height = (el.scrollHeight > el.clientHeight) ? (el.scrollHeight) + "px" : "60px";
    },

    async handleSubmit() {
      const { $db, $users } = this;
      const itemRefID = await $users.getBagelUserID()
      await $db.collection("forum").post({ ...this.newTopic, user: [{ itemRefID }] })
      this.fetchData()
    }
  },
  name: "My Posts",
};
</script>