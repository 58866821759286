import { render, staticRenderFns } from "./Contenthub.vue?vue&type=template&id=111a7fac&scoped=true&"
import script from "./Contenthub.vue?vue&type=script&lang=js&"
export * from "./Contenthub.vue?vue&type=script&lang=js&"
import style0 from "./Contenthub.vue?vue&type=style&index=0&id=111a7fac&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.0_gau6jvuvur4iyctrqsvqjhw7vu/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "111a7fac",
  null
  
)

export default component.exports