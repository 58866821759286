<template >
  <div class="page-wrap">
    <div class="w700">
      <h1 class="center-wrap ">Comments or suggestions?
        <br>
        We'd love to hear from you!
      </h1>
      <form @submit.prevent="sendForm" v-if="formStatus != 'success'" class="form">
        <input id="head" placeholder="Topic" v-model="form.head" type="text" class="login-input" />
        <textarea id="message" placeholder="Message" v-model="form.content" class="login-input big"></textarea>
        <input type="submit" value="Submit" class="btn forum" />

      </form>
      <div class="tnx" v-if="formStatus == 'success'">
        <p class="center-wrap">
          <span class="new-line">Thank you!</span> Your submission has been
          received!
        </p>
      </div>
      <div class="error" v-if="formStatus == 'fail'">
        <p>An error occurred while submitting the forum</p>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data: () => ({
    formStatus: "",
    form: {
      head: "",
      content: "",
    },
  }),
  methods: {
    async sendForm() {
      try {
        const itemRefID = await this.$users.getBagelUserID()
        await this.$db.collection("suggestion").post({ user: [{ itemRefID }], ...this.form});
        this.formStatus = "success";
      } catch (err) {
        console.log(err.response.data);
        this.formStatus = "fail";
      }
    },
  },
  name: "Suggestion",
};
</script>
