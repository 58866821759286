import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import router from "./router";
import Bagel from "@bageldb/bagel-db";
// import BagelToken from "../token";
import Popup from "vue-bgl-popup";
import axios from "axios";
import linkPath from "./components/linkpath.vue";

Vue.component("link-path", linkPath);
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  Vue,
  dsn: "https://14a782e97e604c87b46592185bb79cb6@o1395784.ingest.sentry.io/6728437",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "portal.emagine.care", /^\//],
    }),
  ],
  tracesSampleRate: 0.8,
});

const { encryptString } = require("simple-encrypter");

Vue.prototype.$encrypt = encryptString;

import vClickOutside from "v-click-outside";

Vue.use(vClickOutside);

Vue.use(require("vue-cookies"));

Vue.component("popup", Popup);

const $db = new Bagel('');
$db.baseEndpoint = "/api/baseEndpoint";
$db.options.baseEndpoint = $db.baseEndpoint;
const $users = $db.users();

$users.axios.interceptors.request.use((request)=>{
  if (request.url.match('auth.bageldb')){
    const path = request.url.split('public/').pop()
    request.url = `/api/auth/${path}`
  }
  return request
})


window.logMyError = ({ context, res }) => {
  const log = {};
  log.res = res;
  log.context = context;
  log.page = window.location.href;
  $db.collection("logs").post(log);
};

Vue.prototype.$db = $db;
Vue.prototype.$users = $users;
Vue.$cookies.config("30d");

async function fetchData() {
  const calls = [
    {
      name: "testingSites",
      col: "sites",
    },

    {
      col: "steeringCommittee",
      sort: ["_order", "asc"],
    },
    {
      col: "timeline",
      everything: true,
      sort: ["_order", "asc"],
    },
    {col:'homepage'},
    {
      col: "contactInfo",
    },

    {
      col: "contentHub",
      // query: ["featured", "=", true],
      // perPage: 3
    },
  ];
  try {
    await $users.getUser();
  } catch (err) {
    console.log(err);
    if ((err?.statusCode || err?.status) == 401) $users.logout();
  }
  let responses = [];
  const promises =  calls.map((c) => {
    const call = $db.collection(c.col);
    if (c.sort) call.sort(...c.sort);
    else call.sort("_order", "desc");
    if (c.query) call.query(...c.query);
    if (c.everything) call.everything();
    if (c.perPage) call.perPage(c.perPage);
    return call.get();
  })

  responses = await Promise.all(promises);
  
  const $store = {};
  for (let i in calls) {
    const call = calls[i];
    const data = responses[i]?.data
  
    $store[call.name || call.col] = data
  }
  return $store;
}

Vue.prototype.$axios = axios;

Vue.use(VueRouter);
Vue.config.productionTip = false;

const app = new Vue({
  router,
  render: (h) => h(App),
});

const getrefresh = () => {
  const expires_in = localStorage.getItem("bagel-expires_in");
  const expires = new Date().setSeconds(expires_in);
  if (expires > new Date()) return false;
  const refresh_token = localStorage.getItem("bagel-refresh");
  return refresh_token;
};

async function init() {
  const refresh_token = getrefresh();
  if (refresh_token) {
    try {
      const { data } = await axios.post("/refresh", refresh_token);
      localStorage.setItem("bagel-access", data.access_token);
      localStorage.setItem("bagel-refresh", data.refresh_token);
    } catch (err) {
      $users.logout();
    }
  }
  Vue.prototype.$store = await fetchData();

  app.$mount("#app");
}

init();
