<template >
  <div class="page-wrap">
    <div class="forum-wrap">
      <Forummenu />

      <div class="forum-main">
        <h2 class="forum-small-head">Search Results</h2>
        <div v-for="topic in forum" :key="topic._id" class="topic">
          <p class="topic-head">{{ topic.topicHead }}</p>
          <div class="tags-wrap" v-if="topic.tags">
            <router-link v-for="tag in (topic.tags || '').split(', ')" :key="tag + topic._id" class="tag" :to="`/tag/${tag}`">{{ tag }}</router-link>
          </div>
          <p v-html="topic.topicContent" class="topic-txt">
          </p>
          <div class="topic-info-wrap">
            <div class="topic-user">
              <img v-if="topic.user[0].item.profileImage && topic.user[0].item.profileImage.imageURL" :src="topic.user[0].item.profileImage.imageURL" />
              <div v-else class="topic-userlet">{{ (topic.user[0].item.fullName || "").split("\s+").map(n => n[0]).join('') }}</div>
              <div>
                <p class="topic-user-name"> {{ topic.user[0].item.fullName }}
                </p>
                <p v-if="topic.user[0].item.brainQStaff" class="topic-user-title">BrainQ Staff</p>
              </div>
            </div>
            <div class="topic-info-txt-wrap">

              <p class="topic-info"> {{ new Date(topic._createdDate).toLocaleDateString() }}</p>
              <p class="topic-info">|</p>
              <!-- <p class="topic-info">{{ topic.views }} Views</p> -->
              <!-- <p class="topic-info">|</p> -->
              <p class="topic-info">{{ topic.comments.length }} Replies</p>
            </div>

          </div>
        </div>
      </div>
    </div>
    <router-link to="/suggestion" class="suggestion-box">Suggestion?</router-link>

  </div>
</template>


<script>
import Forummenu from "@/components/ForumMenu.vue";

export default {
  components: {
    Forummenu,
  },
  data: () => ({
    forum: "",
  }),
  async beforeMount() {
    let { data: forum } = await this.$db
      .collection("forum")
      .everything()
      .get();
    this.forum = forum;
  },
  name: "Search-Results",
};
</script>